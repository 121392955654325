class User {
  async hasPermission (permission) {
    return fetch('permission', {name: permission})
      .then(response => {
        return response.json()
      })
      .catch(error => {
        return error
      })
  }

  async getPermissions () {
    return fetch('permissions')
      .then(response => {
        return response.json()
      })
      .catch(error => {
        return error
      })
  }
}

export default new User()

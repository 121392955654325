var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card",
        { attrs: { flat: "" } },
        [
          _c(
            "v-toolbar",
            { attrs: { color: "grey lighten-2", elevation: "0" } },
            [
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on, attrs }) {
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: {
                                    icon: "",
                                    rounded: "",
                                    elevation: "0",
                                    to: { name: "analyzer-structure-list" },
                                  },
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_c("v-icon", [_vm._v("fa-arrow-left-long")])],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.$t("um.btn_back_overview")))]),
                ]
              ),
              _vm._v(" "),
              _c("v-toolbar-title", { staticClass: "ml-2" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.structureName) +
                    "\n                (" +
                    _vm._s(_vm.$t(`um.status.${_vm.status}`)) +
                    ")\n            "
                ),
              ]),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on, attrs }) {
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: {
                                    icon: "",
                                    "input-value": _vm.showFilter,
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.showFilter = !_vm.showFilter
                                    },
                                  },
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_c("v-icon", [_vm._v("fa-filter")])],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.$t("um.label.show_filter")))]),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("asa-table", {
            attrs: {
              data: _vm.tableData,
              loading: _vm.loading,
              itemsPerPageOptions: [10, 50, 100],
              showFilter: _vm.showFilter,
              "hide-footer": "",
              "hide-export": "",
              "disable-pagination": "",
            },
            on: {
              filter_closed: function ($event) {
                _vm.showFilter = false
              },
            },
          }),
          _vm._v(" "),
          _c(
            "v-toolbar",
            { attrs: { color: "grey lighten-2", elevation: "0" } },
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                { attrs: { to: { name: "analyzer-topic-list" }, plain: "" } },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.$t("um.btn_back_overview")) +
                      "\n            "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import Vue from 'vue'
import Router from 'vue-router'

import Analyze from '../views/Analyze'
import AnalyzeTopicList from '../views/analyze/Topics'
import AnalyzeTopic from '../views/analyze/Topic'
import AnalyzeStructureList from '../views/analyze/Structures'
import AnalyzeStructure from '../views/analyze/Structure'

Vue.use(Router)

export default new Router({
    routes: [
        {
            path: '/',
            name: 'home',
            redirect: {name: 'analyzer'},
        }, {
            name: 'analyzer',
            path: '/analyze',
            component: Analyze,
            redirect: {name: 'analyzer-topic-list'},
            meta: {
                breadcrumb: 'um.menu.analyze',
                title: 'um.menu.analyze',
                class: 'secondary'
            },
            children: [
                {
                    name: 'analyzer-topic-list',
                    path: 'topic',
                    component: AnalyzeTopicList,
                }, {
                    name: 'analyzer-structure-list',
                    path: 'organisation-units',
                    component: AnalyzeStructureList,
                }, {
                    name: 'analyzer-topic',
                    path: 'topic/:instruction/:status',
                    component: AnalyzeTopic,
                    props: route => ({instruction: Number(route.params.instruction), status: route.params.status}),
                    meta: {
                        breadcrumb: 'um.analyzer.title.topics',
                        title: 'um.analyzer.title.topic',
                    }
                }, {
                    name: 'analyzer-structure',
                    path: 'organisation-units/:structure/:status',
                    component: AnalyzeStructure,
                    props: true,
                    meta: {
                        breadcrumb: 'um.analyzer.title.structures',
                        title: 'um.analyzer.title.structure',
                    }
                }
            ]
        }
    ]
})

export default class TruncateFormatter {
    constructor(settings) {
        if (typeof settings.maxLength === 'undefined') {
            throw new Error('Setting maxlength is required')
        }
        this.maxLength = settings.maxLength
        this.truncationSuffix = settings.truncationSuffix || '…'
    }

    format(value) {
        let t = typeof value;
        if (!value || t === 'undefined') {
            return '';
        }

        if (!(t === "string")) {
            if (typeof value.toString === 'undefined') {
                throw new Error('Can not convert ' + t + ' to string');
            }
            value = value.toString();
        }

        if (value.length > this.maxLength && value.length > this.maxLength - this.truncationSuffix.length) {
            return value.substring(0, this.maxLength - this.truncationSuffix.length) + this.truncationSuffix;
        } else {
            return value;
        }
    }
}

<template>
    <span v-html="formattedValue" />
</template>

<script>
/* @todo rewrite component */

import FormatterFactory from '../lib/formatter';
import {mapGetters} from "vuex";

export default {
    props: {
        /** the value that will be formatted */
        "value": {
            default: ''
        },
        /**
         * bool, date, datetime, currency, localized, select, checkbox, image,
         * status, statusdetail, striptags, float, text, textarea, locale, time,
         * mimeicon, truncate or link
         */
        "type": {
            type: String,
            default: "",
        },
        /** special case for AsaTable */
        "formatter": {
            type: Object,
            default: null,
        },
        /** options to be passed to the formatter. depends on the formatter selected via the type prop. */
        "options": {
            type: [Object, Array],
            default: () => []
        },
        /** special case for AsaTable */
        "context": {
            type: [Object],
            default: () => {}
        }
    },
    computed: {
        ...mapGetters('lumui/i18n', ['locale']),
        formattedValue() {
            let value = this.value;

            const settings = this.options;

            switch (this.type.toLowerCase()) {
            case 'bool':
                value = this.formatBool(value);
                break;
            case 'date':
                value = FormatterFactory('Date', {locale: this.locale}).format(value);
                break;
            case 'datetime':
                value = FormatterFactory('DateTime', {locale: this.locale}).format(value);
                break;
            case 'currency':
                value = FormatterFactory('Currency', {}).format(value);
                break;
            case 'localized':
                value = FormatterFactory('Localized', {locale: this.locale}).format(value);
                break;
            case 'select':
                value = FormatterFactory('Select', settings).format(value);
                break;
            case 'checkbox':
                value = FormatterFactory('Checkbox', settings).format(value);
                break;
            case 'image':
                value = FormatterFactory('Image', {}).format(value);
                break;
            case 'status':
                value = FormatterFactory('Status', settings).format(value);
                break;
            case 'statusdetail':
                value = FormatterFactory('Statusdetail', settings).format(value);
                break;
            case 'striptags':
                value = FormatterFactory('Striptags', {}).format(value);
                break;
            case 'float':
                value = FormatterFactory('Float', {locale: this.locale}).format(value);
                break;
            case 'text':
            case 'textarea':
                // nothing to do here
                break;
            case 'locale':
                value = FormatterFactory('Locale', {}).format(value);
                break;
            case 'time':
                value = FormatterFactory('Time', {}).format(value);
                break;
            case 'mimeicon':
                value = FormatterFactory('MimeIcon', {}).format(value);
                break;
            case 'nestedset':
                value = FormatterFactory('NestedSet', settings).format(value);
                break;
            case 'truncate':
                value = FormatterFactory('Truncate', settings).format(value);
                break;
            case 'link':
                value = FormatterFactory('Link', settings).format(value, this.context);
                break;
            default:
                if (this.type) {
                    console.error('AsaFormatter: unknown type ' + this.type);
                }
                break;
            }

            if (this.formatter) {
                Object.keys(this.formatter).forEach((formatterName) => {
                    try {
                        //console.log(formatterName)
                        //console.log(this.formatter[formatterName])
                        let f = FormatterFactory(formatterName, this.formatter[formatterName]);
                        value = f.format(value, this.context);
                    } catch (e) {
                        console.error(this.type, e);
                    }
                })
            }

            return value;
        }
    },
    methods: {
        /**
         * @param {boolean} value
         * @return {string} Localized truth value
         */
        formatBool(value) {
            return value ? this.$lumui.i18n.t('lumui.formatter.boolean.true') : this.$lumui.i18n.t('lumui.formatter.boolean.false');
        },
    }
}
</script>

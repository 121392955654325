<template>
    <div>
        <v-card flat>
            <v-toolbar
                color="grey lighten-2"
                elevation="0"
            >
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            icon
                            rounded
                            elevation="0"
                            :to="{name: 'analyzer-topic-list'}"
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-icon>fa-arrow-left-long</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('um.btn_back_overview') }}</span>
                </v-tooltip>
                <v-toolbar-title class="ml-2">
                    {{ instructionName }}
                    ({{ $t(`um.status.${status}`) }})
                </v-toolbar-title>
                <v-spacer />
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            icon
                            @click="showFilter = !showFilter"
                            v-bind="attrs"
                            v-on="on"
                            :input-value="showFilter"
                        >
                            <v-icon>fa-filter</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('um.label.show_filter') }}</span>
                </v-tooltip>
            </v-toolbar>
            <asa-table
                :data="tableData"
                :loading="loading"
                :itemsPerPageOptions="[10, 50, 100]"
                :showFilter="showFilter"
                @filter_closed="showFilter = false"
                hide-footer
                hide-export
                disable-pagination
            >
            </asa-table>
            <v-toolbar
                color="grey lighten-2"
                elevation="0"
            >
                <v-spacer />
                <v-btn :to="{name: 'analyzer-topic-list'}" plain>
                    {{ $t('um.btn_back_overview') }}
                </v-btn>
            </v-toolbar>
        </v-card>
    </div>
</template>

<script>
import Loading from "../../mixins/loading"
import Status from "../../mixins/status"
import api from "../../api";
import {mapGetters} from "vuex";

export default {
    name: "Topic",
    mixins: [
        Loading,
        Status
    ],
    props: {
        instruction: {
            type: Number,
            required: true
        },
        status: {
            type: String,
            required: false,
            default: null,
            validator(value) {
                return [null, 'done', 'due', 'overdue', 'planed'].includes(value)
            }
        }
    },
    data() {
        return {
            tab: 0,
            data: [],
            instructions: [],
            showFilter: true,
            treeNodes: [],
        }
    },
    computed: {
        ...mapGetters({
            lang: 'currentLocale'
        }),
        langTranslate () {
            return this.lang.replace('_', '-')
        },
        instructionName () {
            return this.instructionObject !== null ? this.instructionObject.name[this.langTranslate] : null
        },
        instructionObject () {
            const instruction = this.instructions.find(item => item.id === this.instruction)
            return typeof instruction !== 'undefined' ? instruction : null
        },
        tableData() {
            return {
                columns: [{
                        key: 'id',
                        hidden: true
                    }, {
                        label: this.$t('um.label.user.name'),
                        key: 'user'
                    }, {
                        label: this.$t('um.assignment.status'),
                        key: 'status',
                        details: {
                            hidden: true
                        },
                        formatter: {
                            KeyValue: [{
                                0: '<i style="color: green" class="fas fa-check"></i>',
                                1: '<i style="color: orange" class="fas fa-exclamation-triangle"></i>',
                                2: '<i style="color: red" class="fas fa-exclamation-circle"></i>',
                                3: '<i style="color: blue" class="fas fa-clock"></i>',
                                4: '<i style="color: darkred" class="fas fa-bolt"></i>',
                            }]
                        },
                        export: {
                            formatter: {
                                KeyValue: [{
                                    0: this.$t('um.status.done'),
                                    1: this.$t('um.status.due'),
                                    2: this.$t('um.status.overdue'),
                                    3: this.$t('um.status.planed'),
                                    4: this.$t('um.status.noticed'),
                                }]
                            }
                        }
                    }, {
                        label: this.$t('um.assignment.status'),
                        key: 'statusName',
                        hidden: true,
                        details: {
                            hidden: false
                        },
                        export: {
                            hidden: true
                        }
                    }, {
                        key: 'instruction',
                        type: 'localized',
                        label: this.$t('um.label.instruction.name'),
                        hidden: true,
                        details: {
                            hidden: false
                        }
                    }, {
                        key: 'user_active',
                        label: this.$t('um.label.user.active'),
                        hidden: true,
                        details: {
                            hidden: false
                        }
                    }, {
                        key: 'structure',
                        label: this.$t('um.label.structure'),
                        type: 'nestedset',
                        details: {
                            hidden: false
                        }
                    }, {
                        key: 'instruction_id',
                        hidden: true,
                    }, {
                        key: 'interval',
                        label: this.$t('um.label.interval.name'),
                        hidden: true,
                        details: {
                            hidden: false
                        }
                    }, {
                        key: 'createTime',
                        label:  this.$t('um.analyzer.createTime'),
                        type: 'date',
                        hidden: true,
                        details: {
                            hidden: false
                        }
                    }, {
                        key: 'lastTime',
                        label:  this.$t('um.analyzer.lastTime'),
                        type: 'date',
                        hidden: true,
                        details: {
                            hidden: false
                        }
                    },  {
                        key: 'nextTime',
                        label:  this.$t('um.label.nextTime'),
                        type: 'date'
                    }, {
                        key: 'actions',
                        label: '',
                        type: 'action',
                        actions: {
                        },
                    }
                ],
                rows: this.data,
                filter: [
                    {
                        key: 'user',
                        label: this.$t('um.label.user.name'),
                    },
                    {
                        key: 'user_active',
                        label: this.$t('um.label.user.active'),
                        value: 1,
                        filter: {
                            select: {
                                options: [
                                    { value: 1, text: this.$t('um.label.user.active') },
                                    { value: 0, text: this.$t('um.label.user.inactive') }
                                ]
                            }
                        }
                    },
                    {
                        key: 'structure',
                        label: this.$t('um.label.structure.name'),
                        filter: {
                            nestedset: this.treeNodes
                        }
                    },
                    {
                        key: 'createTime',
                        label: this.$t('um.analyzer.createTime'),
                        type: 'date',
                        filter: {
                            date: {
                                range: true,
                            },
                        }
                    },
                    {
                        key: 'lastTime',
                        label: this.$t('um.analyzer.lastTime'),
                        type: 'date',
                        filter: {
                            date: {
                                range: true,
                            },
                        }
                    },
                    {
                        key: 'nextTime',
                        label: this.$t('um.label.nextTime'),
                        type: 'date',
                        filter: {
                            date: {
                                range: true,
                            },
                        }
                    },
                    {
                        key: 'status',
                        label: this.$t('um.label.status'),
                        filter: {
                            select: {
                                options: {
                                    0: this.$t('um.status.done'),
                                    1: this.$t('um.status.due'),
                                    2: this.$t('um.status.overdue'),
                                    3: this.$t('um.status.planed'),
                                    4: this.$t('um.status.noticed'),

                                }
                            }
                        }
                    }
                ],
                defaultSort: {
                    sortBy: ['user'],
                    sortDesc: [false]
                }
            }
        },
        statusNum () {
            return this.statusValueByName(this.status)
        }
    },
    methods: {
    },
    async mounted() {
        this.setLoading()
        await api.data.tree().then((result) => {
            this.treeNodes = result
        })
        await api.data.instructions().then((result) => {
            this.instructions = result
        })
        await api.data.assignments()
            .then((result) => {
                this.data = result
                    .filter(item => {
                        return (this.status !== null && item.status === this.statusNum) && item.instruction.id === this.instruction
                    })
                    .map(row => {
                        let lastTime = null;
                        for (let certificate of row.certificates) {
                            if (!lastTime || lastTime <= certificate.createTime) {
                                lastTime = certificate.createTime;
                            }
                        }
                        if (!lastTime) {
                            lastTime = '';
                        }
                        return {
                            id: row.id,
                            user: row.user.name,
                            user_active: row.user.active,
                            user_categories: row.user.categories,
                            instruction: row.instruction.name,
                            instruction_id: row.instruction.id,
                            profile: row.profile || '',
                            structure: {
                                label: row.user.structure.name,
                                left: row.user.structure.left,
                                right: row.user.structure.right,
                                root: row.user.structure.root_id,
                            },
                            interval: row.interval.name,
                            createTime: row.createTime,
                            lastTime: lastTime ,
                            nextTime: row.nextDate || '',
                            status: row.status,
                            statusName: this.$t('um.status.' + this.statusNameByValue(row.status)),
                        }
                    })
            })
            .finally(() => this.setLoaded())
    }
}
</script>

<template>
    <v-textarea
        :id="id"
        v-model="viewValue"
        filled
        :class="config.class"
        :disabled="disabled"
        :error="externalError"
        :error-messages="errorMessages"
        :hint="config.description || ''"
        :label="config.label"
        :name="name"
        :placeholder="config.placeholder || ''"
        :required="required"
        :rules="computedRules"
        :counter="config.maxLength || false"
        autocomplete="off"
        @update:error="updateError"
    />
</template>

<script>
import {VTextarea} from 'vuetify/lib';
import Validatable from 'vuetify/lib/mixins/validatable';

/**
 * #### Config
 *
 *
 * | key                    | type                       | required | default     | description |
 * |------------------------|----------------------------|----------|-------------|-------------|
 * | type                   | `String`                   | yes      |             | field type  |
 * | label                  | `String`, `false`          | no       | `false`     | fields label |
 * | description            | `String`                   | no       | `""`        | field description |
 * | class                  | `String`                   | no       | `null`      | css class for custom styling |
 * | required               | `Boolean`, `eval(String)`  | no       | `false`     | field is required.|
 * | disabled               | `Boolean`, `eval(String)`  | no       | `false`     | field is disabled.|
 * | visible                | `Boolean`, `eval(String)`  | no       | `false`     | field is rendered.  |
 * | prefix                 | `String`                   | no       | `undefined` | Adds prefix to field. |
 * | suffix                 | `String`                   | no       | `undefined` | Adds suffix to field. |
 * | maxLength              | `Number`                   | no       | `false`     | Limit input to maxLenght chars. Also display length counter. |
 * | placeholder            | `String`                   | no       | `""`        | placeholder displayed inside the field, when value is empty |
 * | hideDetails            | `String`, `Bool`           | no       | `undefined` | When set to "auto" details are only rendered when necessary
 */
export default {
    name: "FormRowTextarea",
    components: {VTextarea},
    mixins: [Validatable],
    props: {
        /** element id */
        id: {
            type: String,
            required: true
        },
        /** element name */
        name: {
            type: String,
            required: true
        },
        /** validation errors exist */
        error: {
            type: Boolean,
            required: false,
            default: false
        },
        /** validation messages */
        errorMessages: {
            type: Array,
            required: false,
            default: () => []
        },
        /** field is required */
        required: {
            type: Boolean,
            required: false,
            default: false
        },
        /** field is disabled */
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        value: {
            required: false,
            default: ""
        },
        /** element configuration */
        config: {
            type: Object,
            required: true
        },
        /** validation rules */
        rules: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    data() {
        return {
            viewValue: ""
        }
    },
    computed: {
        externalError() {
            return this.errorBucket.length > 0 || this.error;
        },
        computedRules() {
            const rules = this.rules;
            if (this.config.maxLength) {
                rules.push((val) => String(val).length < this.config.maxLength)
            }
        }
    },
    watch: {
        viewValue() {
            this.$emit('input', this.viewValue);
        },
        value: {
            deep: true,
            handler(newVal) {
                this.viewValue = newVal;
            }
        }
    },
    mounted() {
        this.viewValue = this.value;

        if ((this.value === null || typeof this.value === 'undefined')) {
            this.viewValue = this.config.value;
            this.updateValue();
        }
    },
    methods: {
        /** @private */
        updateValue() {
            /**
             * user input
             * @param {String} viewValue
             */
            this.$emit('input', this.viewValue);
        },
        /** @private */
        updateError(e) {
            /** validation error detected */
            this.$emit('update:error', e)
        },
    },
}
</script>

export default class NestedSetFormatter {
    constructor(settings)
    {
        this.label = settings.label || 'label';
    }

    format(value) {
        if(!value) {
            return "";
        }
        if(typeof value === 'object' && value.hasOwnProperty(this.label)) {
            return value[this.label];
        } else {
            return String(value);
        }
    }
}

<template>
    <v-input
        :id="id"
        :name="name"
        filled
        :disabled="disabled"
        :error="hasError"
        :error-messages="msgs"
        :label="config.label"
        :required="required"
        :rules="rules"
        class="input--colorpicker"
    >
        <v-color-picker
            v-model="viewValue"
            filled
            :disabled="disabled"
            mode="hexa"
            hide-inputs
            hide-canvas
            hide-mode-switch
        />
    </v-input>
</template>

<script>
import {VInput, VColorPicker} from 'vuetify/lib';

/**
 * Color picker.
 *
 * #### Config
 *
 * | key                    | type                       | required | default    | description |
 * |------------------------|----------------------------|----------|------------|-------------|
 * | type                   | `String`                   | yes      | -          | always `"colorpicker"` |
 * | label                  | `String`, `false`          | no       | `false`    | fields label |
 * | required               | `Boolean`, `eval(String)`  | no       | false      | field is required.|
 * | disabled               | `Boolean`, `eval(String)`  | no       | false      | field is disabled.|
 * | visible                | `Boolean`, `eval(String)`  | no       | false      | field is rendered.  |
 */
export default {
    name: "FormRowColorPicker",
    components: {VInput, VColorPicker},
    props: {
        /** element id */
        id: {
            type: String,
            required: true
        },
        /** field value */
        value: {
            type: String,
            required: false,
            default: "#FF0000"
        },
        /** field config */
        config: {
            type: Object,
            required: true
        },
        /** element name */
        name: {
            type: String,
            required: true
        },
        /** validation errors exist */
        hasError: {
            type: Boolean,
            default: false,
            required: false
        },
        /** validation messages */
        msgs: {
            type: Array,
            default: () => [],
            required: false
        },
        /** field is required */
        required: {
            type: Boolean,
            default: false,
            required: false
        },
        /** validation rules */
        rules: {
            type: Array,
            default: () => [],
            required: false
        },
        /** field is disabled */
        disabled: {
            type: Boolean,
            default: false,
            required: false
        },
    },
    data() {
        return {
            viewValue: {
                hex : '#FFFFFF'
            },
        }
    },
    watch: {
        'viewValue.hex': function(){
            this.updateValue()
        },
        value(newValue) {
            this.$set(this.viewValue, 'hex', newValue)
        }
    },
    mounted() {
        this.$set(this.viewValue, 'hex', this.value)
    },
    methods: {
        /** @private */
        updateValue() {
            /**
             * user input
             * @param {String} hex - select color in hexadecimal notation
             */
            this.$emit('input', this.viewValue.hex);
        }
    }
}
</script>

<style lang="scss">
.v-color-picker {
    min-width: 300px;
}
.v-color-picker__alpha {
    display: none;
}
.v-color-picker__hue {
    align-items: center !important;
    justify-content: center !important;
    margin-bottom: 0 !important;
}
.input--colorpicker .v-input__slot {
    display: block;
}
</style>

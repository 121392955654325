<template>
  <v-system-bar v-if="showLogoutWarning" dark color="info" style="z-index:1000;">
    <v-layout>
      <v-icon>fa-exclamation-triangle</v-icon>
      <span class="flex-grow-1 text-center white--text">{{ $t('Sie werden demnächst automatisch abgemeldet.') }}</span>
      <span class="white--text">{{ formattedTimer }}</span>
    </v-layout>
  </v-system-bar>
</template>

<script>
import {VSystemBar, VLayout, VIcon} from 'vuetify/lib'

export default {
  name: "LogoutWarning",
  components: {VSystemBar, VLayout, VIcon},
  data() {
    return {
      timeTillLogout: 1800000,
      logoutTime: Date.now() + this.timeTillLogout,
      remainingTime: this.timeTillLogout,
      logoutInterval: null,
      keepAliveInterval: null,
      disabled: false
    }
  },
  computed: {
    showLogoutWarning() {
      return !this.disabled && this.remainingTime < 300;
    },
    formattedTimer() {
      return (new Date(this.remainingTime * 1000)).toISOString().substring(14, 19)
    },
    keepAliveUrl() {
      if (typeof window.applications !== 'undefined' && window.applications.hasOwnProperty('login')) {
        let url = window.applications.login.baseUrl
        if (url && !url.endsWith('/')) {
          url += '/'
        }
        return url + 'keepalive';
      }
      return null;
    },
    navigation() {
      return window.navigation;
    },
  },
  mounted: async function () {
    this.resetTimer();
    window.addEventListener("mousemove", this.resetTimer);
    window.addEventListener("touchstart", this.resetTimer);
    window.addEventListener("touchmove", this.resetTimer);
    this.logoutInterval = window.setInterval(
      this.logoutHandler,
      1000
    )

    this.keepAliveInterval = window.setInterval(async () => {
      try {
        await this.$axios.get(this.keepAliveUrl, {withCredentials: true})
      } catch(e) {
        console.error(`Keepalive failed`, e)
      }

    }, 300000)
    this.disabled = false;
    window.addEventListener('unload', this.cleanup)
  },
  beforeDestroy() {
    this.cleanup();
  },
  methods: {
    logoutHandler() {
      this.remainingTime = (this.logoutTime - Date.now()) / 1000;
      if (this.remainingTime < 0) {
        window.sessionStorage.setItem('alert', JSON.stringify(this.$t('Sie wurden automatisch ausgeloggt')));
        this.logout();
      }
    },
    logout() {
      window.location.href = navigation['zzz_logout'].target
    },
    cleanup() {
      if (this.keepAliveInterval) {
        window.clearInterval(this.keepAliveInterval);
        this.keepAliveInterval = null;
      }
      if (this.logoutInterval) {
        window.clearInterval(this.logoutInterval);
        this.logoutInterval = null;
      }
      window.removeEventListener("mousemove", this.resetTimer)
      window.removeEventListener("touchstart", this.resetTimer)
      window.removeEventListener("touchmove", this.resetTimer)
    },
    disableAutoLogout() {
      if (this.logoutInterval) {
        window.clearInterval(this.logoutInterval);
        this.logoutInterval = null;
      }
      this.disabled = true;
    },
    enableAutoLogout() {
      this.disabled = false;
      if (this.logoutInterval) {
        return;
      }
      this.resetTimer();
      this.logoutInterval = window.setInterval(
        this.logoutHandler,
        1000
      )
    },
    resetTimer() {
      this.logoutTime = Date.now() + this.timeTillLogout;
    }
  }
}
</script>

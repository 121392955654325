<template>
    <v-app>
        <router-view/>
    </v-app>
</template>

<script>
import {VApp} from 'vuetify/lib';
export default {
    name: "UvAdminShell",
    components: {
        VApp
    },
    mounted() {
        if (window.route) {
            let route = {name: window.route, params: window.routeParams || {}};
            this.$router.replace(route);
        }
    }
}
</script>

<template>
    <div>
        <v-text-field
            :id="name"
            v-model="viewValue"
            filled
            :disabled="disabled"
            :error="error"
            :error-count="errorMessages.length"
            :error-messages="errorMessages"
            :hint="config.description || ''"
            :label="config.label"
            :name="name"
            :required="required"
            :rules="rules"
            autocomplete="off"
            :type="reveal ? 'text' : 'password'"
            :append-icon="reveal ? '$hide' : '$reveal'"
            @click:append="reveal = !reveal"
        />
        <v-text-field
            v-if="!config.no_confirm"
            :id="name+'_confirm'"
            ref="confirm"
            v-model="confirm"
            filled
            :disabled="disabled"
            :label="$lumui.i18n.t('lumui.form.password.repeat')"
            :name="name+'_confirm'"
            :required="viewValue !== ''"
            :rules="[v => { return (viewValue === v) || $lumui.i18n.t('lumui.form.password.not_matching_error') }]"
            autocomplete="off"
            :type="reveal ? 'text' : 'password'"
        />
    </div>
</template>

<script>
import {VTextField} from 'vuetify/lib';

/**
 * | key                    | type                       | required | default    | description |
 * |------------------------|----------------------------|----------|------------|-------------|
 * | type                   | `String`                   | yes      |            | field type  |
 * | label                  | `String`, `false`          | no       | `false`    | fields label |
 * | required               | `Boolean`, `eval(String)`  | no       | `false`    | field is required.|
 * | disabled               | `Boolean`, `eval(String)`  | no       | `false`    | field is disabled.|
 * | visible                | `Boolean`, `eval(String)`  | no       | `false`    | field is rendered.  |
 * | description            | `String`                   | no       | `""`       | field hint |
 * | no_confirm             | `Boolean`                  | no       | `false`    | do not require confirmation |
 */
export default {
    name: "FormRowPassword",
    components: {VTextField},
    props: {
        /** value */
        value: {
            type: String,
            default: ""
        },
        /** field config */
        config: {
            type: Object,
            default: null
        },
        /** field name */
        name: {
            type: String,
            default: ""
        },
        /** validation errors exist */
        error: {
            type: Boolean,
            default: false,
        },
        /** validation messages */
        errorMessages: {
            type: Array,
            default: () => [],
        },
        /** field is required */
        required: {
            type: Boolean,
            default: false,
        },
        /** validate when field looses focus */
        validateOnBlur: {
            type: Boolean,
            default: true,
        },
        /** field is disabled */
        disabled: {
            type: Boolean,
            default: false
        },
        /** validation rules */
        rules: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            viewValue: null,
            confirm: null,
            reveal: false
        }
    },
    watch: {
        viewValue() {
            if (!this.config.no_confirm) {
                this.$refs.confirm.validate();
            }
            /**
             * user input
             * @param {String} viewValue
             */
            this.$emit('input', this.viewValue);
        },
        value(newValue) {
            this.viewValue = newValue;
        }
    },
    mounted() {
        if (this.value) {
            this.viewValue = this.value
        }
    }
}
</script>

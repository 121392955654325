<template>
  <v-card>
    <v-alert type="error" tile>
      {{ $t('uv.not_found_title') }}
    </v-alert>
    <v-card-text>
      <p v-html="$t('uv.not_found_msg')">
      </p>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "NotFound"
}
</script>

<style scoped>

</style>
import {VuetifyPlugin,VuetifyInstance} from "./plugins/vuetify.js";
import AxiosPlugin from "./plugins/axios.js";
import FosRoutingPlugin from "./plugins/fos-routing.js";

// Find all components in lib
const requireComponent = require.context('./component', false, /\.vue$/, 'sync');

let components = {};
const UvPlugin = {
  install(Vue, options) {
    requireComponent.keys().forEach((fileName) => {
      const componentConfig = requireComponent(fileName);
      const componentName = fileName
        .split('/')
        .pop()
        .replace(/\.vue$/, '');
      components[componentName] = Vue.component(componentName, componentConfig.default || componentConfig)
    })


    let errorComponents = {
      'Symfony\\Component\\HttpKernel\\Exception\\NotFoundHttpException': () => import('./component/NotFound.vue')
    }
    if (options && options.hasOwnProperty('errorComponents')) {
      errorComponents = Object.assign(errorComponents, options.errorComponents)
    }
    Vue.prototype.$errorComponents = errorComponents
  }
};

export default UvPlugin;

export {
  VuetifyPlugin,
  VuetifyInstance,
  AxiosPlugin,
  FosRoutingPlugin,
  UvPlugin
};

(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("axios"), (function webpackLoadOptionalExternalModule() { try { return require("fos-jsrouting"); } catch(e) {} }()));
	else if(typeof define === 'function' && define.amd)
		define(["axios", "fos-jsrouting"], factory);
	else if(typeof exports === 'object')
		exports["lum-uv-ui"] = factory(require("axios"), (function webpackLoadOptionalExternalModule() { try { return require("fos-jsrouting"); } catch(e) {} }()));
	else
		root["lum-uv-ui"] = factory(root["axios"], root["fos-jsrouting"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__300__, __WEBPACK_EXTERNAL_MODULE__302__) => {
return 
export default class CurrencyFormatter {
    constructor() {
    }

    format(value) {
        let parsedValue = Number.parseFloat(value);
        if(isNaN(parsedValue)) {
            return ''
        } else {
            return parsedValue.toFixed(2) + ' sFr.'
        }
    }
}

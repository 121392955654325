import axios from 'axios';

const AxiosPlugin = {
    install(Vue) {
        const service = axios.create({
            withCredentials: true
        });

        /*
        service.interceptors.request.use(
            config => {
                // console.log("Request", config);
                return config;
            },
            error => {
                // console.error('Error sending request', error);
                return Promise.reject(error)
            }
        );
        */

        service.interceptors.response.use(
            response => response,
            error => {
                let data = error.response.data;
                if (data.class && data.detail) {
                    if (data.trace) {
                        let trace = "";
                        data.trace.forEach((e, i) => {
                            trace += "#" + (i + 1) + " " + e.file + ':' + e.line + ' ' + (e.class ? e.class : '') + (e.type ? e.type : '')
                                + (e.function ? e.function + "()" : "") + "\n";
                        });
                        console.error(data.class + ' ' + data.detail + ":\n" + trace);
                    } else {
                        console.error(data.class + ' ' + data.detail);
                    }
                }
                return Promise.reject(error);
            }
        );
        Vue.prototype.$axios = service;
        Vue.prototype.$http = {
            request(method, route, routeParams, data, options) {
                let url = Vue.prototype.backendRoute(route, routeParams);
                let axiosOpt = {
                    ...options,
                    ...{
                        method: method,
                        url: url,
                        data: data
                    }
                };
                return service(axiosOpt).then((response) => {
                    if (response.status !== 200) {
                        throw new Error('Failed to ' + method + ' ' + url + ': (' + response.status + ') ' + response.statusText)
                    }
                    return response.data;
                });
            },
            call: (route, routeParams = {}, data = {}, options = {}) => {
                const r = Vue.prototype.$backendRouting.getRoute(route);
                if (!r) {
                    throw new Error('Route ' + route + ' does not exist');
                }
                if (r.methods.length > 1) {
                    throw new Error('Ambiguous method for ' + route + ': '.r.methods.map((x) => x.toLowerCase()).join(', '));
                }
                const method = r.methods.length === 0 ? 'get' : r.methods[0];
                return Vue.prototype.$http.request(method, route, routeParams, data, options);
            },
            get: (route, routeParams = {}, options = {}) => {
                return Vue.prototype.$http.request('get', route, routeParams, {}, options);
            },
            post: (route, routeParams = {}, data = {}, options = {}) => {
                return Vue.prototype.$http.request('post', route, routeParams, data, options);
            },
            put: (route, routeParams = {}, data = {}, options = {}) => {
                return Vue.prototype.$http.request('put', route, routeParams, data, options);
            },
            patch: (route, routeParams = {}, data = {}, options = {}) => {
                return Vue.prototype.$http.request('patch', route, routeParams, data, options);
            },
            delete: (route, routeParams = {}, options = {}) => {
                return Vue.prototype.$http.request('delete', route, routeParams, data, options);
            },
            download: (route, routeParams = {}, options = {}) => {
                let url = Vue.prototype.backendRoute(route, routeParams);
                return service.get(url, {
                    ...options,
                    responseType: 'arraybuffer',
                    headers: {
                        'Accept': '*/*'
                    }
                }).then((res) => {
                    const headers = res.headers;

                    let filename = "";
                    if (headers["content-disposition"]) {
                        const parts = headers["content-disposition"].split('filename=');
                        if (parts.length === 2) {
                            filename = parts[1];
                        }
                    }
                    const blob = new Blob([res.data]);

                    if (typeof window.navigator.msSaveBlob !== 'undefined') {
                        window.navigator.msSaveBlob(blob, filename);
                        return;
                    }

                    const blobURL = window.URL.createObjectURL(blob);
                    const tempLink = document.createElement('a');
                    tempLink.style.display = 'none';
                    tempLink.href = blobURL;
                    if (filename) {
                        tempLink.setAttribute('download', filename);
                    }
                    document.body.appendChild(tempLink);
                    tempLink.click();
                    document.body.removeChild(tempLink);
                    window.URL.revokeObjectURL(blobURL);
                });
            }
        }
    }
};
export default AxiosPlugin;

import Vuetify from 'vuetify/lib';
import deepmerge from "deepmerge";

let VuetifyInstance = null;
let installed = false;

const VuetifyPlugin = {
  install(Vue, config = {}) {
    if (installed) {
      throw Error('UV/UI plugin already installed');
    }
    installed = true;
    Vue.use(Vuetify);
    let cfg = deepmerge({
      icons: {
        iconfont: 'fa',
        values: {
          'arrow_drop_down': 'fa-caret-down',
          'arrow_drop_up': 'fas fa-caret-up',
        }
      },
      theme: {
        themes: {
          light: {
            primary: '#cd1619',
            secondary: '#f6f6f6',
            accent: '#940000',
            error: '#ff5643',
            info: '#2196F3',
            success: '#4CAF50',
            warning: '#FFC107',
          },
        },
        options: {customProperties: true},
      },
    }, config)
    VuetifyInstance = new Vuetify(cfg);
  }
}
export {VuetifyPlugin, VuetifyInstance};

<template>
    <v-container>
        <h1>{{ $t('mail.error_boundary.title') }}</h1>
        <p>{{ $t('mail.error_boundary.message') }}</p>

        <template v-if="env === 'development'">
            Error location: {{ info }}
            <template v-if="err.isAxiosError">
                <h2 v-if="err.response.data.class && err.response.data.detail">{{ err.response.data.class }}:
                    {{ err.response.data.detail }}</h2>
                <template v-else>{{ err }}</template>
                <!-- @formatter:off -->
                <pre v-if="err.response.data.trace" v-html="trace(err.response.data.trace)">
<template v-for="(e,i) in err.response.data.trace">#{{ i }} {{ e.file }}:{{ e.line }} {{ e.class }}{{ e.type }}{{ e.function }}()</template>
                </pre>
                <!-- @formatter:on -->
            </template>
            <template v-else-if="err.stack">
                <h2>{{ err.message }}</h2>
                <pre>{{ err.stack }}</pre>
            </template>
            <pre v-else>{{ err }}</pre>
        </template>
    </v-container>
</template>

<script>
import {VContainer} from 'vuetify/lib';
export default {
    name: "UvError",
    components: {
        VContainer
    },
    props: {
        env: {
            type: String,
            required: true
        },
        err: {
            required: true
        },
        vm: {
            type: Object,
            required: true
        },
        info: {
            type: String,
            required: true
        }
    },
    methods: {
        pad(n) {
            let s = "";
            for (let i = 0; i < n; i++) {
                s += " ";
            }
            return s;
        },
        location(nr, file, line) {
            return file + ":" + line;
        },
        trace(trace) {
            let res = "";
            let longest = 0;
            trace.forEach((e, i) => {
                let l = this.location(i, e.file, e.line).length;
                if (l > longest) {
                    longest = l;
                }
            });
            trace.forEach((e, i) => {
                let location = this.location(i, e.file, e.line);
                let n = longest - location.length;
                res += '#' + (i + 1) + ' <a href="phpstorm://?file=' + encodeURI(e.file) + '&line=' + e.line + '">' + location + '</a>' + this.pad(n + 1) + (e.class ? e.class : '') + (e.type ? e.type : '')
                    + (e.function ? e.function + "()" : "") + "\n";
            });
            return res;
        }
    }
}
</script>

<style scoped>

</style>

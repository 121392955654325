<template>
    <div>
        <div class="d-flex justify-end mb-2">
            <v-btn text>
                <v-icon left>fa-chart-pie</v-icon>
                {{$t('um.analyzer.export')}}
            </v-btn>
            <v-btn text color="accent">
                <v-icon left>fa-file-certificate</v-icon>
                {{$t('um.analyzer.cert_export')}}
            </v-btn>
        </div>

        <v-tabs grow>
            <v-tab :to="{name: 'analyzer-topic-list'}">{{ $t('um.analyzer.title.topics') }}</v-tab>
            <v-tab :to="{name: 'analyzer-structure-list'}">{{ $t('um.analyzer.title.structures') }}</v-tab>
        </v-tabs>
        <router-view />
    </div>
</template>

<script>

export default {
    name: "List",
    data() {
        return {
        }
    },
    computed: {

    }
}
</script>

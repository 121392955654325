<template>
  <v-app :id="appId">
    <logout-warning ref="logoutWarning" />
    <v-navigation-drawer id="menu" v-model="sidebarCollapsed" :width="300" app class="d-print-none">
      <template #prepend>
        <v-sheet class="toolbar-top" flat light>
          <div class="d-flex justify-space-between align-center w-100">
            <v-avatar @click="gotoProfile()">
              <v-icon v-if="!profile.image" :size="50">
                fa-user-circle
              </v-icon>
              <v-img v-else :src="profile.image" />
            </v-avatar>
            <template v-if="moduleLogo !== null">
              <v-img class="module-logo" :src="moduleLogo" />
            </template>
          </div>

          <a class="mt-auto">
            <div class="d-flex justify-space-between align-end">
              <div class="flex-grow-1 personal-area">
                <div class="text-subtitle1 primary--text font-weight-medium">
                  {{ $t('uv.shell.profile') }}
                </div>
                <div class="text-body-2 font-weight-medium">
                  {{ profile.firstName }} {{ profile.lastName }}
                </div>
              </div>
              <v-tooltip bottom>
                <template #activator="{on, attrs}">
                  <v-icon class="flex-grow-0 mx-2 my-1" v-bind="attrs" v-on="on" @click="gotoProfile()">fa-cog</v-icon>
                </template>
                <span>{{ $t('uv.shell.profile') }}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template #activator="{on, attrs}">
                  <v-icon
                    class="flex-grow-0 mx-2 my-1" v-bind="attrs" v-on="on"
                    @click="goto(navigation['zzz_logout'].target)"
                  >
                    fa-sign-out
                  </v-icon>
                </template>
                <span>{{ $t('uv.shell.logout') }}</span>
              </v-tooltip>
            </div>
          </a>
        </v-sheet>
        <v-list class="menu-list pb-0" dense nav>
          <v-list-item class="application-item grey lighten-4 mb-0" two-line>
            <v-list-item-action class="mr-3">
              <v-menu
                bottom
                close-on-click
                transition="slide-x-transition"
                :open-on-hover="!$vuetify.breakpoint.smAndDown"
                :max-width="200"
                :nudge-width="200"
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    class="module-menu-btn"
                    color="grey darken-2"
                    outlined
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon large>
                      fas fa-caret-down
                    </v-icon>
                  </v-btn>
                </template>
                <v-list v-if="$vuetify.breakpoint.smAndDown">
                  <v-list-item
                    v-for="(group, key) in sortedNavigationGroups"
                    :key="key"
                    @click="goto(group.target, group.targetAttr)"
                  >
                    <v-list-item-icon>
                      <v-icon>{{ group.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                      {{ $t(group.text) }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
                <v-card v-else>
                  <v-card-text style="background: white">
                    <template v-for="(group, key) in sortedNavigationGroups">
                      <v-tooltip :key="key" bottom>
                        <template #activator="{on, attrs}">
                          <v-btn
                            fab
                            :color="activeMenuGroup === key ? 'primary' : null"
                            elevation="0"
                            v-bind="attrs"
                            class="ma-2"
                            height="40"
                            width="40"
                            v-on="on"
                            @click="goto(group.target, group.targetAttr)"
                          >
                            <v-icon small>
                              {{ group.icon }}
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>
                          {{ $t(group.text) }}
                        </span>
                      </v-tooltip>
                    </template>
                  </v-card-text>
                </v-card>
              </v-menu>
            </v-list-item-action>
            <v-list-item-title>
              <div class="grey--text text--darken-1 body-2 application-title pl-2">
                {{ $t('UV Software Suite') }}
              </div>
              <div
                v-if="typeof navigation[activeMenuGroup] !== 'undefined'"
                class="primary--text body-1 module-title pl-2 ml-4"
              >
                {{ $t(navigation[activeMenuGroup].text) }}
              </div>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </template>
      <v-list class="menu-list" dense nav>
        <template
          v-if="sortedNavigationGroups.hasOwnProperty(activeMenuGroup) && typeof sortedNavigationGroups[activeMenuGroup] !== 'undefined'"
        >
          <template v-for="item in sortChildren(sortedNavigationGroups[activeMenuGroup].children)">
            <v-list-item
              v-if="!item.hasOwnProperty('children')"
              :id="item.id"
              :key="item.id"
              :class="{'v-list-item--active': matched(item.target) }"
              :href="item.target"
              :target="item.targetAttr ? item.targetAttr : '_self'"
              :set="itemMatched = matched(item.target)"
            >
              <v-list-item-icon v-if="item.icon !== null">
                <v-icon v-html="item.icon" />
              </v-list-item-icon>
              <v-list-item-title>{{ $t(item.text) }}</v-list-item-title>
            </v-list-item>
            <v-list-group
              v-else-if="sidebarCollapsed && item.hasOwnProperty('children')"
              :key="item.id"
              :prepend-icon="item.icon"
              :value="matchedChildren(item.children)"
              active-class="v-list-item--active"
            >
              <template #activator>
                <v-list-item-title>{{ $t(item.text) }}</v-list-item-title>
              </template>
              <v-list-item
                v-for="child in sortChildren(item.children)"
                :id="child.id"
                :key="child.target"
                :class="{'v-list-item--active': matched(child.target) }"
                :href="child.target"
                :target="child.targetAttr ? child.targetAttr : '_self'"
                :set="childMatched = matched(child.target)"
                :value="child.target"
                link
              >
                <v-list-item-icon v-if="child.icon !== null">
                  <v-icon v-html="child.icon" />
                </v-list-item-icon>
                <v-list-item-title>
                  {{ $t(child.text) }}
                </v-list-item-title>
              </v-list-item>
            </v-list-group>
            <v-menu v-else :key="item.id" offset-x>
              <template #activator="{ on }">
                <v-list-item
                  :id="item.id"
                  :class="{'v-list-item--active': matched(item.target) }"
                  :set="childMatched = matchedChildren(item.children)"
                  v-on="on"
                >
                  <v-list-item-icon v-if="item.icon !== null">
                    <v-icon v-html="item.icon" />
                  </v-list-item-icon>
                  <v-list-item-title>{{ $t(item.text) }}</v-list-item-title>
                </v-list-item>
              </template>
              <v-list class="pa-4" dens>
                <v-list-item
                  v-for="child in item.children"
                  :id="child.id"
                  :key="child.target"
                  :class="{'v-list-item--active': matched(child.target) }"
                  :href="child.target"
                  :target="child.targetAttr ? child.targetAttr : '_self'"
                  :set="childMatched = matched(child.target)"
                  class="ml-2"
                  link
                >
                  <v-list-item-icon v-if="item.icon !== null">
                    <v-icon v-html="child.icon" />
                  </v-list-item-icon>
                  <v-list-item-title>
                    {{ $t(child.text) }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </template>
      </v-list>
      <template #append>
        <v-img :src="require('../static/uv_digital.png')" contain height="35px" position="left center" class="ma-4" />
      </template>
    </v-navigation-drawer>

    <v-app-bar app fixed prominent>
      <v-container :fluid="mainContainerFluid">
        <v-app-bar-nav-icon class="align-self-end mr-2" color="primary" @click="sidebarCollapsed = !sidebarCollapsed">
          <v-icon>fa-bars</v-icon>
        </v-app-bar-nav-icon>
        <v-toolbar-title>
          <img :src="require('../static/ehs_logo.png')" alt="EHS Webmanager" style="height: 50px;">
        </v-toolbar-title>
        <v-spacer />
        <div class="logo align-self-end" />
      </v-container>
    </v-app-bar>

    <v-main :class="mainClass">
      <v-container :fluid="mainContainerFluid">
        <div class="page-header">
          <uv-breadcrumbs :root="{text: title, to: {name: 'home'}, disabled: true}" />
          <h1 v-if="pageTitle.id" class="text--primary">
            {{ $t(pageTitle.id, pageTitle.args) }}
          </h1>
        </div>
        <div class="page fill-height">
          <router-view />
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import {
  VApp,
  VAppBar,
  VAppBarNavIcon,
  VAvatar,
  VBtn,
  VCard,
  VCardText,
  VContainer,
  VIcon,
  VImg,
  VList,
  VListGroup,
  VListItem,
  VListItemAction,
  VListItemIcon,
  VListItemTitle,
  VMain,
  VMenu,
  VNavigationDrawer,
  VSheet,
  VSpacer,
  VTooltip,
  VToolbarTitle
} from 'vuetify/lib';
import UvBreadcrumbs from "./UvBreadcrumbs";
import LogoutWarning from "./LogoutWarning";

export default {
  name: "UVShell",
  components: {
    UvBreadcrumbs,
    LogoutWarning,
    VApp,
    VAppBar,
    VAppBarNavIcon,
    VAvatar,
    VBtn,
    VCard,
    VCardText,
    VContainer,
    VIcon,
    VImg,
    VList,
    VListGroup,
    VListItem,
    VListItemAction,
    VListItemIcon,
    VListItemTitle,
    VMain,
    VMenu,
    VNavigationDrawer,
    VSheet,
    VSpacer,
    VTooltip,
    VToolbarTitle
  },
  props: {
    env: {
      type: String,
      default: 'production'
    },
    title: {
      type: String,
      required: true
    },
    /* wir die Prop noch irgendwo verwendet? */
    myroute: {
      type: Object,
      default() {
        return {path: 'user'}
      },
      required: false
    }
  },
  data() {
    return {
      showModuleMenu: false,
      sidebarCollapsed: !this.$vuetify.breakpoint.smAndDown,
      profile: {
        id: '',
        firstName: '',
        lastName: '',
        image: ''
      },
    };
  },
  computed: {
    module() {
      let url = window.location.origin
      if (typeof this.applications === 'undefined') {
        return undefined
      }
      return Object.values(this.applications).find(application => {
        return application.baseUrl.startsWith(url)
      })
    },
    basedata() {
      if (typeof window.applications !== 'undefined' && window.applications.hasOwnProperty('basedata')) {
        return window.applications['basedata'];
      }
      return null;
    },
    basedataUrl() {
      let b = this.basedata.baseUrl;
      if (!b.endsWith('/')) {
        b += '/';
      }
      return b;
    },
    logoUrl() {
      return this.basedataUrl + 'api/logo/?d=' + new Date().getTime();
    },
    profileUrl() {
      return this.basedataUrl + 'api/user/profile';
    },
    profileEditUrl() {
      return this.basedataUrl + '#/profile/edit';
    },
    applications() {
      if (window.applications) {
        return window.applications
      } else {
        return {}
      }
    },
    activeMenuGroup() {
      if (window.menu_group) {
        return window.menu_group;
      } else {
        return '_my';
      }
    },
    currentRouteName() {
      return this.$route.name || "";
    },
    sortedNavigationGroups() {
      return Object.keys(this.navigation)
        .sort()
        .filter((o) => {
          return o !== 'zzz_logout'
        })
        .reduce((obj, key) => {
          obj[key] = this.navigation[key];
          return obj;
        }, {});
    },
    sortedNavigation() {
      return Object.keys(this.navigation)
        .sort()
        .filter((o) => {
          const isCurrentModule = typeof this.module !== 'undefined' && this.module.id === o;
          const isApp = typeof this.applications[o] !== 'undefined';
          const isLogout = o === 'zzz_logout'
          return isCurrentModule || (!isApp && !isLogout);
        })
        .reduce((obj, key) => {
          obj[key] = this.navigation[key];
          return obj;
        }, {});
    },
    navigation() {
      return window.navigation;
    },
    mainClass() {
      if (this.$route) {
        let matched = this.$route.matched;
        for (const r of matched) {
          if (r.meta && r.meta.class) {
            return r.meta.class
          }
        }
      }
      return ""
    },
    /* Toggles main container to fluid */
    mainContainerFluid() {
      if (this.$route) {
        let matched = this.$route.matched;
        for (const r of matched) {
          if (r.meta && r.meta.mainContainerFluid) {
            return r.meta.mainContainerFluid
          }
        }
      }
      return false
    },
    pageTitle() {
      if (this.$route) {
        // clone and reverse the array to match child first
        let matched = [...this.$route.matched];
        for (const r of matched.reverse()) {
          if (r.meta && r.meta.title) {
            return {
              id: r.meta.title,
              args: r.meta.titleArgs || [],
            }
          }
        }
      }
      return {
        id: "",
        args: []
      };
    },
    showLogo() {
      return this.hasUser ? !this.sidebarCollapsed : true
    },
    appId() {
      if (this.$route.name === null) {
        return null
      }
      return this.$route.name.toLowerCase()
    },
    // module specific logo
    moduleLogo() {
      return window.module_logo ? window.module_logo : null;
    }
  },
  mounted() {
    this.loadProfile();
  },
  methods: {
    goto(url, target) {
      if(typeof target !== 'undefined' && target === '_blank') {
        window.open(url, '_blank')
      } else {
        window.location.href = url;
      }
    },
    gotoProfile() {
      if (this.profileEditUrl !== false) {
        window.location.href = this.profileEditUrl;
      }
    },
    sortChildren(children) {
      if (!Array.isArray(children)) {
        return children;
      }
      let order = 0;
      let childrenWithOrder = children.map(x => {
        if (!x.order) {
          x.order = 1000000 + order++;
        }
        return x;
      })
      return childrenWithOrder.sort((a, b) => {
        if (a.order < b.order) {
          return -1;
        } else if (a.order > b.order) {
          return 1;
        } else {
          return 0;
        }
      });
    },
    matched(target) {
      return window.location.href.startsWith(target) || window.location.hash === target;
    },

    matchedChildren(items) {
      const protoHost = window.location.protocol + '//' + window.location.host;
      for (const i in items) {
        if (!items.hasOwnProperty(i)) {
          continue;
        }
        const target = items[i].target;
        if (target.startsWith(protoHost) || target.startsWith(window.location.hash)) {
          return true;
        }
      }
      return false;
    },
    async loadProfile() {
      try {
        if (this.basedata) {
          let res = await this.$axios.get(this.profileUrl);
          this.profile = res.data
        } else {
          console.log('Basedata not found');
        }
      } catch (e) {
        console.error(e);
      }
    },
    disableAutoLogout() {
      this.$refs.logoutWarning.disableAutoLogout();
    },
    enableAutoLogout() {
      this.$refs.logoutWarning.enableAutoLogout();
    }
  }
}
</script>

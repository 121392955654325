import Vue from "vue";

export default class CheckboxFormatter {
    constructor(options)
    {
        this.options = options;
    }

    format(value) {
        return value
            ? Vue.prototype.$lumui.i18n.t('lumui.formatter.boolean.true')
            : Vue.prototype.$lumui.i18n.t('lumui.formatter.boolean.false');
    }
}

import Vue from 'vue'
import Vuex from 'vuex'

import {loader} from './loader.module'
import {user} from './user.module'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    loader,
    user
  },
  strict: debug,
  state: {
  },
  getters: {
    currentLocale: (state, rootGetters) => {
      return rootGetters['lumui/i18n/locale'].replace('-', '_')
    },
    currentLocales: (state, rootGetters) => {
      return rootGetters['lumui/i18n/locales'].map((item) => {
        return item.replace('-', '_')
      })
    }
  }
})

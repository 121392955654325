import {mapGetters, mapMutations} from "vuex";

export default {
    computed: {
        ...mapGetters({
          loading: 'loader/loading'
        })
    },
    methods: {
        ...mapMutations({
            showLoading: 'loader/show',
            hideLoading: 'loader/hide'
        }),
        setLoading() {
            this.showLoading();
        },
        setLoaded() {
            this.hideLoading();
        }
    }
}

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card",
        { attrs: { flat: "" } },
        [
          _c("asa-table", {
            attrs: {
              data: _vm.tableData,
              loading: _vm.loading,
              itemsPerPageOptions: [10, 50, 100],
              "hide-footer": "",
              "hide-export": "",
              "disable-pagination": "",
              "info-disabled": "",
            },
            scopedSlots: _vm._u([
              {
                key: "col_done",
                fn: function (props) {
                  return [
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          to: {
                            name: "analyzer-structure",
                            params: { structure: props.row.id, status: "done" },
                          },
                          disabled: props.row.done === 0,
                          text: "",
                          small: "",
                        },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(props.row.done) +
                            "\n                "
                        ),
                      ]
                    ),
                  ]
                },
              },
              {
                key: "col_due",
                fn: function (props) {
                  return [
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          to: {
                            name: "analyzer-structure",
                            params: { structure: props.row.id, status: "due" },
                          },
                          disabled: props.row.due === 0,
                          text: "",
                          small: "",
                        },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(props.row.due) +
                            "\n                "
                        ),
                      ]
                    ),
                  ]
                },
              },
              {
                key: "col_overdue",
                fn: function (props) {
                  return [
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          to: {
                            name: "analyzer-structure",
                            params: {
                              structure: props.row.id,
                              status: "overdue",
                            },
                          },
                          disabled: props.row.overdue === 0,
                          text: "",
                          small: "",
                        },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(props.row.overdue) +
                            "\n                "
                        ),
                      ]
                    ),
                  ]
                },
              },
              {
                key: "col_planed",
                fn: function (props) {
                  return [
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          to: {
                            name: "analyzer-structure",
                            params: {
                              structure: props.row.id,
                              status: "planed",
                            },
                          },
                          disabled: props.row.planed === 0,
                          text: "",
                          small: "",
                        },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(props.row.planed) +
                            "\n                "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
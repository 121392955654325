
const FosRoutingPlugin = {
    install(Vue, options) {
        try {
            const Routing = require('fos-jsrouting');
            Vue.prototype.$backendRouting = Routing;
            Vue.prototype.backendRoute = function (name, params = {}) {
                const Routing = Vue.prototype.$backendRouting;
                return Routing.generate(name, params);
            };
            if (typeof window.DevBackendRoutes !== 'undefined') {
                console.debug('Setting dev routes');
                Routing.setRoutingData(window.DevBackendRoutes);
            } else {
                console.debug('Setting compiled routes');
                Routing.setRoutingData(options);
            }
        } catch (e) {
            console.warn('fos routing is not available');
            console.debug(e);
            Vue.prototype.backendRoute = function (name) {
                return '/fos-js-routing-is-not-available/' + name
            }
        }
    }
};

export default FosRoutingPlugin;

<template>
  <v-app>
    <v-app-bar app fixed prominent>
      <v-container>
        <v-toolbar-title class="ml-12">
          {{ $t('Universum') }} <span class="text--primary">{{ $t('Software Suite') }}</span>
        </v-toolbar-title>
        <v-spacer/>
        <div class="logo"/>
      </v-container>
    </v-app-bar>

    <v-main>
      <v-container>
        <div class="page-header">
          <uv-breadcrumbs :root="{text: title, to: {name: 'home'}, disabled: true}"/>
          <h1 v-if="pageTitle.id" class="text--primary">
            {{ $t(pageTitle.id, pageTitle.args) }}
          </h1>
          <h1 v-else>

          </h1>
        </div>
        <div class="page fill-height">
          <component v-bind:is="errorComponent"></component>
          <v-card v-if="env !== 'production'" class="my-4">
            <v-card-title>Exception Data</v-card-title>
            <v-card-text>
              <pre>{{ exception }}</pre>
            </v-card-text>
          </v-card>
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import {
  VApp,
  VAppBar,
  VCard,
  VCardText,
  VCardTitle,
  VContainer,
  VMain,
  VSheet,
  VSpacer,
  VToolbarTitle
} from 'vuetify/lib';
import UvBreadcrumbs from "./UvBreadcrumbs";

export default {
  name: "UvErrorShell",
  components: {
    VApp,
    VAppBar,
    VCard,
    VCardText,
    VCardTitle,
    VContainer,
    VMain,
    VSheet,
    VSpacer,
    VToolbarTitle
  },
  props: {
    env: {
      type: String,
      default: 'production'
    },
    title: {
      type: String,
      required: true
    },
  },
  computed: {
    pageTitle() {
      if (window.pageTitle) {
        return {
          id: window.pageTitle,
          args: []
        }
      } else {
        return {
          id: "Fehler",
          args: []
        }
      }
    },
    exception() {
      if (window.exception) {
        return JSON.parse(window.exception)
      } else {
        return null;
      }
    },
    exceptionClass() {
      if (window.exceptionClass) {
        return window.exceptionClass
      } else {
        return 'fallback'
      }
    },
    errorComponent() {
      if (this.$errorComponents.hasOwnProperty(this.exceptionClass)) {
        return this.$errorComponents[this.exceptionClass]
      } else {
        return null
      }
    }
  },
}
</script>

<style scoped>

</style>

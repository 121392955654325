<template>
  <div class="caption">
    {{ $t('uv.breadcrumb.title') }}
    <v-breadcrumbs divider="»" :items="breadcrumbs" class="px-0 pb-0"/>
  </div>
</template>

<script>
import {VBreadcrumbs} from 'vuetify/lib';
import {mapGetters} from "vuex";

export default {
  name: "UVBreadcrumbs",
  components: {
    VBreadcrumbs
  },
  props: {
    root: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      breadcrumbs: []
    }
  },
  watch: {
    "$i18n.locale": {
      handler(l) {
        if (!l) {
          return;
        }
        console.log("locale changed to " + l)
        this.updateBreadcrumbs();
      }
    },
    '$route': {
      deep: true,
      handler() {
        this.updateBreadcrumbs();
      }
    },
  },
  computed: {
    ...mapGetters('lumui/i18n', ['locale']),
    translatedRoot() {
      const r = Object.assign({}, this.root);
      r.title = this.$t(r.title);
      return r;
    },
  },
  methods: {
    updateBreadcrumbs() {
      let crumbs = [this.translatedRoot];
      if (!this.$route.matched) {
        return crumbs;
      }
      this.$route.matched.forEach((route) => {
        if (!route.meta || !route.meta.breadcrumb) {
          return;
        }
        let meta = route.meta.breadcrumb;
        let crumb;
        if (typeof meta.breadcrumb) {
          if (typeof meta === 'string') {
            crumb = {
              text: this.$t(meta),
              to: {name: route.name, params: this.$route.params},
              exact: true
            };
          } else if (typeof meta === 'function') {
            crumb = meta(route, this.$route.params);
            if (typeof crumb != 'string') {
              crumb.exact = true;
            }
          } else if (Array.isArray(meta)) {
            crumb = meta.map((c) => {
              if (c && typeof c.text !== 'undefined') {
                c.text = this.$t(c.text)
              }
              return c;
            }, this);
          }
        }
        crumbs.push(crumb);
      }, this);
      Promise.all(crumbs).then((res) => {
        let finalResult = [];
        res.forEach((crumb) => {
          if (Array.isArray(crumb)) {
            finalResult.push(...crumb);
          } else {
            finalResult.push(crumb);
          }
        })

        finalResult = finalResult.map((item) => {
          if (typeof item !== 'undefined' && typeof item.to === 'object') {
            item.exact = true;
          }
          return item;
        });
        this.breadcrumbs = finalResult;
      });
    },
  },
  mounted() {
    this.updateBreadcrumbs();
  }
}
</script>

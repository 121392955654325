import api from "../api";

export const user = {
  namespaced: true,
  state: {
    userId: null,
    company: null,
    permissions: []
  },
  getters: {
    getId: (state) => {
      return state.userId
    },
    company: (state) => {
      return typeof state.company !== 'undefined' ? state.company : null
    },
    permissions: (state) => {
      return state.permissions
    },
    permission: (state) => {
      return (permission) => {
          let item = state.permissions.find(item => item === permission)
          return typeof item !== 'undefined'
      }
    }
  },
  mutations: {
    setId (state, userId) {
      state.userId = userId
    },
    setCompany (state, company) {
      state.company = company
    },
    setPermissions(state, permissions) {
        state.permissions = permissions
    }
  },
  actions: {
    updateCompany: ({commit}) => {
      api.company.get()
          .then((result) => {
              commit('setCompany', result);
          });
    },
    updatePermissions: ({commit}) => {
        if (localStorage.getItem('permissions')) {
            commit('setPermissions', JSON.parse(localStorage.getItem('permissions')));
        }
        api.user.getPermissions()
          .then((result) => {
              commit('setId', result.id)
              commit('setPermissions', result.permissions);
              localStorage.setItem('permissions', JSON.stringify(result.permissions));
          });
    }
  },
  modules: {}
}

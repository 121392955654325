import Vue from 'vue'
import UI, {i18n} from '@lum/ui'
import router from './router'
import store from './store'
import moment from 'moment';
import {AxiosPlugin, UvPlugin, VuetifyInstance, VuetifyPlugin, FosRoutingPlugin} from '@lum/uv-ui'
import translations from './translations'
import '@fortawesome/fontawesome-pro/css/all.css';
import './scss/app.scss';

require('moment/locale/de')
Vue.use(require('vue-moment'), { moment })
Vue.use(VuetifyPlugin)
Vue.use(FosRoutingPlugin)
Vue.use(AxiosPlugin)
Vue.use(UI, {
  vuetify: VuetifyInstance,
  store,
  table: {
    persistentFilters: true
  },
  translations,
  locale: window.locale,
  fallbackLocale: 'de-DE'
})
Vue.use(UvPlugin)
Vue.config.productionTip = false

/* eslint-disable no-new */
new Vue({
  vuetify: VuetifyInstance,
  i18n,
  router,
  store,
  render: h => h(Vue.component('UvShell'), {
    ref: 'UvShell',
    props: {
      env: process.env.NODE_ENV,
      title: i18n.t('um.app_title')
    }
  })
}).$mount('#app')

(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("vue"), require("moment"), require("md5"), require("vue-debounce"), require("vue-signature-pad"), require("vuetify/lib/components/VDialog"), require("vuetify/lib/components/VCard"), require("vuetify/lib"), require("vuetify/lib/mixins/validatable"), require("filesize"), require("file-saver"), require("qr-scanner"), require("jspdf-autotable"), require("jspdf"), require("xlsx"));
	else if(typeof define === 'function' && define.amd)
		define(["vue", "moment", "md5", "vue-debounce", "vue-signature-pad", "vuetify/lib/components/VDialog", "vuetify/lib/components/VCard", "vuetify/lib", "vuetify/lib/mixins/validatable", "filesize", "file-saver", "qr-scanner", "jspdf-autotable", "jspdf", "xlsx"], factory);
	else if(typeof exports === 'object')
		exports["lumui"] = factory(require("vue"), require("moment"), require("md5"), require("vue-debounce"), require("vue-signature-pad"), require("vuetify/lib/components/VDialog"), require("vuetify/lib/components/VCard"), require("vuetify/lib"), require("vuetify/lib/mixins/validatable"), require("filesize"), require("file-saver"), require("qr-scanner"), require("jspdf-autotable"), require("jspdf"), require("xlsx"));
	else
		root["lumui"] = factory(root["vue"], root["moment"], root["md5"], root["vue-debounce"], root["vue-signature-pad"], root["vuetify/lib/components/VDialog"], root["vuetify/lib/components/VCard"], root["vuetify/lib"], root["vuetify/lib/mixins/validatable"], root["filesize"], root["file-saver"], root["qr-scanner"], root["jspdf-autotable"], root["jspdf"], root["xlsx"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__748__, __WEBPACK_EXTERNAL_MODULE__3192__, __WEBPACK_EXTERNAL_MODULE__3971__, __WEBPACK_EXTERNAL_MODULE__8648__, __WEBPACK_EXTERNAL_MODULE__1481__, __WEBPACK_EXTERNAL_MODULE__3741__, __WEBPACK_EXTERNAL_MODULE__6638__, __WEBPACK_EXTERNAL_MODULE__6537__, __WEBPACK_EXTERNAL_MODULE__370__, __WEBPACK_EXTERNAL_MODULE__2197__, __WEBPACK_EXTERNAL_MODULE__3346__, __WEBPACK_EXTERNAL_MODULE__9352__, __WEBPACK_EXTERNAL_MODULE__1850__, __WEBPACK_EXTERNAL_MODULE__9676__, __WEBPACK_EXTERNAL_MODULE__9748__) => {
return 
import Vue from 'vue';

const vue = new Vue();

// FILES FORMATTER braucht als obj : id,type,originalName der anzuzeigenden Files
export default class FilesFormatter {
    format(value) {
        let rtn = '';
        if (typeof value === 'object' && value !== null) {
            for (let [id, obj] of Object.entries(value)) {
                let spanName = 'filesformatter' + id;
                if (obj.type !== 'external') {
                    if (!isNaN(obj.id) && obj.id > 0) {
                        vue.$call('api.core.asset.lookup.getFileData', {id: obj.id})
                            .then((response) => {
                                let link = '';
                                if (response !== null && response.src !== null) {
                                    link = '<a ' +
                                        'href="#" ' +
                                        '@click.prevent="saveAs(' + this.dataURItoBlob(response.src) + ', ' + obj.title + ')"' +
                                        '>' +
                                        obj.title +
                                        '</a>';
                                } else {
                                    link = '<a ' +
                                        'href="#" ' +
                                        '@click.prevent=""' +
                                        '>' +
                                        obj.title +
                                        '</a>';
                                }
                                let placeholders = document.querySelectorAll(`span[name=${spanName}]`);
                                for (let placeholder of placeholders) {
                                    placeholder.innerHTML = link;
                                }
                            });
                        //todo click wenn files
                        //src, name, mimetype, size
                    }
                    rtn += `<span name="${spanName}">${obj.title}</span><br>`;
                } else {
                    //todo url
                }
            }
        }
        return rtn;
    }

    dataURItoBlob(dataURI, mimeString) {
        let byteString = atob(dataURI.split(',')[1]);
        if (typeof mimeString === 'undefined') {
            mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
        }
        let ab = new ArrayBuffer(byteString.length);
        let ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], {type: mimeString});
    }
}

class Data {
  async assignments () {
    return fetch('/data/assignments.json')
      .then(response => {
        return response.json()
      })
      .catch(error => {
        return error
      })
  }
  async instructions () {
    return fetch('/data/instructions.json')
      .then(response => {
        return response.json()
      })
      .catch(error => {
        return error
      })
  }

  async tree () {
    return fetch('/data/tree.json')
      .then(response => {
        return response.json()
      })
      .catch(error => {
        return error
      })
  }
}

export default new Data()

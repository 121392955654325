var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "d-flex justify-end mb-2" },
        [
          _c(
            "v-btn",
            { attrs: { text: "" } },
            [
              _c("v-icon", { attrs: { left: "" } }, [_vm._v("fa-chart-pie")]),
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$t("um.analyzer.export")) +
                  "\n        "
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-btn",
            { attrs: { text: "", color: "accent" } },
            [
              _c("v-icon", { attrs: { left: "" } }, [
                _vm._v("fa-file-certificate"),
              ]),
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$t("um.analyzer.cert_export")) +
                  "\n        "
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-tabs",
        { attrs: { grow: "" } },
        [
          _c("v-tab", { attrs: { to: { name: "analyzer-topic-list" } } }, [
            _vm._v(_vm._s(_vm.$t("um.analyzer.title.topics"))),
          ]),
          _vm._v(" "),
          _c("v-tab", { attrs: { to: { name: "analyzer-structure-list" } } }, [
            _vm._v(_vm._s(_vm.$t("um.analyzer.title.structures"))),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    _vm._b(
      {
        ref: "form",
        attrs: { "lazy-validation": "" },
        on: { submit: _vm.preventEnter },
        model: {
          value: _vm.valid,
          callback: function ($$v) {
            _vm.valid = $$v
          },
          expression: "valid",
        },
      },
      "v-form",
      _vm.$attrs,
      false
    ),
    [
      _c(
        "v-card",
        { attrs: { flat: _vm.flat } },
        [
          !_vm.noTitle
            ? _c(
                "v-toolbar",
                {
                  class: (_vm.flat ? "" : "elevation-1 ") + "flex-grow-0",
                  attrs: { color: "primary", dark: "" },
                  scopedSlots: _vm._u(
                    [
                      _vm.hasTabs
                        ? {
                            key: "extension",
                            fn: function () {
                              return [
                                _c("asa-form-tabs", {
                                  attrs: {
                                    config: _vm.config,
                                    "render-tabs": true,
                                    value: _vm.value,
                                    "active-tab": _vm.activeTab,
                                  },
                                  on: { tab: _vm.setActiveTab },
                                }),
                              ]
                            },
                            proxy: true,
                          }
                        : null,
                    ],
                    null,
                    true
                  ),
                },
                [
                  _c(
                    "v-toolbar-title",
                    [
                      _vm._t(
                        "title",
                        function () {
                          return [
                            _vm._v(
                              "\n                    Untitled\n                "
                            ),
                          ]
                        },
                        { config: _vm.config }
                      ),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _vm._t("title-after", null, { config: _vm.config }),
                  _vm._v(" "),
                  _vm.abortBtn
                    ? [
                        _c(
                          "v-icon",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.$router.back()
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                    $close\n                "
                            ),
                          ]
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              )
            : _vm.hasTabs
            ? _c(
                "v-toolbar",
                { attrs: { color: "white flex-grow-0", light: "" } },
                [
                  _c("asa-form-tabs", {
                    attrs: {
                      config: _vm.config,
                      "render-tabs": true,
                      value: _vm.value,
                      "active-tab": _vm.activeTab,
                      color: "primary",
                      "background-color": "white",
                    },
                    on: { tab: _vm.setActiveTab },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm._t("before", null, { config: _vm.config }),
          _vm._v(" "),
          _c(
            "v-card-text",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
            },
            [_c("asa-loading", { attrs: { loading: _vm.loading } })],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.loading,
                  expression: "!loading",
                },
              ],
              staticStyle: { "margin-top": "2px" },
            },
            [
              _vm._t("form-before", null, { config: _vm.config }),
              _vm._v(" "),
              _vm._t(
                "form-errors",
                function () {
                  return [
                    _vm.formErrors
                      ? _vm._l(_vm.formErrors, function (error, key) {
                          return _c(
                            "v-alert",
                            {
                              key: key,
                              staticClass: "mb-4",
                              attrs: { value: true, type: "error" },
                            },
                            [
                              !Array.isArray(error)
                                ? [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(error) +
                                        "\n                        "
                                    ),
                                  ]
                                : _vm._l(error, function (e, i) {
                                    return _c("div", { key: i }, [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(e) +
                                          "\n                            "
                                      ),
                                    ])
                                  }),
                            ],
                            2
                          )
                        })
                      : _vm._e(),
                  ]
                },
                { config: _vm.config, formErrors: _vm.formErrors }
              ),
              _vm._v(" "),
              _vm._t(
                "default",
                function () {
                  return [
                    _c("form-content", {
                      attrs: {
                        config: _vm.config,
                        value: _vm.value,
                        "active-tab": _vm.activeTab,
                      },
                      on: {
                        triggerValidation: function ($event) {
                          return _vm.validate()
                        },
                        "update:error": _vm.debouncedUpdateCompoundErrorState,
                      },
                    }),
                  ]
                },
                {
                  config: _vm.config,
                  validate: _vm.validate,
                  value: _vm.value,
                  activeTab: _vm.activeTab,
                }
              ),
              _vm._v(" "),
              _vm._t("form-after", null, { config: _vm.config }),
            ],
            2
          ),
          _vm._v(" "),
          _vm._t("after", null, { config: _vm.config }),
          _vm._v(" "),
          _c(
            "v-card-actions",
            {
              class: {
                "flex-column": _vm.$vuetify.breakpoint.mobile,
                "flex-wrap": !_vm.$vuetify.breakpoint.mobile,
                mobile: _vm.$vuetify.breakpoint.mobile,
                "form-actions": true,
              },
            },
            [
              _vm._t(
                "actions",
                function () {
                  return [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.loading,
                            expression: "!loading",
                          },
                        ],
                      },
                      [
                        !_vm.readonly
                          ? _c(
                              "v-btn",
                              {
                                staticClass: "mx-2 mb-2",
                                attrs: {
                                  disabled:
                                    _vm.loading ||
                                    !_vm.valid ||
                                    _vm.config === null ||
                                    Object.keys(_vm.config).length === 0,
                                  block: _vm.$vuetify.breakpoint.mobile,
                                  color: "success",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.$emit("save")
                                  },
                                },
                              },
                              [
                                _vm._t("save-btn-name", function () {
                                  return [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(
                                          _vm.$lumui.i18n.t("lumui.form.save")
                                        ) +
                                        "\n                        "
                                    ),
                                  ]
                                }),
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.abortBtn
                          ? _c(
                              "v-btn",
                              {
                                staticClass: "mx-2 mb-2",
                                attrs: {
                                  type: "button",
                                  block: _vm.$vuetify.breakpoint.mobile,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.$router.back()
                                  },
                                },
                              },
                              [
                                _vm._t("back-btn-name", function () {
                                  return [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(
                                          _vm.readonly
                                            ? _vm.$lumui.i18n.t(
                                                "lumui.form.close"
                                              )
                                            : _vm.$lumui.i18n.t(
                                                "lumui.form.cancel"
                                              )
                                        ) +
                                        "\n                        "
                                    ),
                                  ]
                                }),
                              ],
                              2
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                },
                { formValid: _vm.valid, config: _vm.config }
              ),
              _vm._v(" "),
              _vm._t("additional-actions", null, {
                formValid: _vm.valid,
                config: _vm.config,
              }),
            ],
            2
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
const translations = {};
const translationContext = require.context('../translations/', false, /\.json$/, 'lazy-once');
const localeData = window.localeData || {};
translationContext.keys().forEach((file) => {
    const locale = file.match('^./messages\.\(.*\).json')[1].replace('_', '-');

    if (localeData[locale]) {
        translations[locale] = window.localeData[locale];
    } else {
        translations[locale] = () => {
            return translationContext(file)
        }
    }
})
export default translations;

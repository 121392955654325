export default class CheckboxFormatter {
    constructor(options)
    {
        this.map = options;
    }

    format(value) {
        if(typeof value !== 'object' || !Array.isArray(this.map)){
            value = [value];
        }
        let labels = '';
        this.map.forEach((option) => {
            if(value != null && value.indexOf(option.value)){

                labels += option.text || option.label;
                labels += '<br>';
            }
        });
        return labels;
    }
}

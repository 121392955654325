import Vue from "vue";
export default class LocalizedFormatter {
    constructor(settings)
    {
        this.locale = settings.locale;
    }

    format(value) {
        if(!value) {
            return "";
        }
        const $lumui = Vue.prototype.$lumui;
        const localeKey = $lumui.mapLocale(this.locale);
        if(typeof value === 'object' && Object.keys(value).includes(localeKey)) {
            return value[localeKey];
        } else {
            const fallback = $lumui.mapLocale($lumui.i18n.fallbackLocale);
            return value[fallback] || JSON.stringify(value);
        }
    }
}

<template>
    <div>
        <v-card flat>
            <asa-table
                :data="tableData"
                :loading="loading"
                :itemsPerPageOptions="[10, 50, 100]"
                hide-footer
                hide-export
                disable-pagination
                info-disabled
            >
                <template v-slot:col_done="props">
                    <v-btn
                        :to="{name: 'analyzer-structure', params: {structure: props.row.id, status: 'done'}}"
                        :disabled="props.row.done === 0"
                        text
                        small
                    >
                        {{ props.row.done }}
                    </v-btn>
                </template>
                <template v-slot:col_due="props">
                    <v-btn
                        :to="{name: 'analyzer-structure', params: {structure: props.row.id, status: 'due'}}"
                        :disabled="props.row.due === 0"
                        text
                        small
                    >
                        {{ props.row.due }}
                    </v-btn>
                </template>
                <template v-slot:col_overdue="props">
                    <v-btn
                        :to="{name: 'analyzer-structure', params: {structure: props.row.id, status: 'overdue'}}"
                        :disabled="props.row.overdue === 0"
                        text
                        small
                    >
                        {{ props.row.overdue }}
                    </v-btn>
                </template>
                <template v-slot:col_planed="props">
                    <v-btn
                        :to="{name: 'analyzer-structure', params: {structure: props.row.id, status: 'planed'}}"
                        :disabled="props.row.planed === 0"
                        text
                        small
                    >
                        {{ props.row.planed }}
                    </v-btn>
                </template>
            </asa-table>
        </v-card>
    </div>
</template>

<script>
import Loading from "../../mixins/loading"
import Status from "../../mixins/status"
import api from "../../api";

export default {
    name: "Topics",
    mixins: [
        Loading,
        Status
    ],
    data() {
        return {
            tab: 0,
            numStatus: {
                done: 0,
                due: 0,
                overdue: 0,
                planed: 0
            },
            data: []
        }
    },
    computed: {
        tableData () {
            return {
                columns: [
                    {
                        key: 'id',
                        hidden: true
                    }, {
                        label: this.$t('um.analyzer.column.structure'),
                        key: 'name'
                    }, {
                        label: `${this.$t('um.status.done')} (${this.numStatus.done})`,
                        key: 'done'
                    }, {
                        label: `${this.$t('um.status.due')} (${this.numStatus.due})`,
                        key: 'due'
                    }, {
                        label: `${this.$t('um.status.overdue')} (${this.numStatus.overdue})`,
                        key: 'overdue'
                    }, {
                        label: `${this.$t('um.status.planed')} (${this.numStatus.planed})`,
                        key: 'planed'
                    }
                ],
                rows: this.data,
                filter: []
            }
        }
    },
    methods: {
        async getData() {
            this.setLoading()
            await api.data.assignments()
                .then((result) => {
                    const list = {}
                    result.forEach(row => {
                        const uuid = row.user.structureUuid
                        if(!list.hasOwnProperty(uuid)) {
                            list[uuid] = {
                                name: row.user.structureName,
                                id: uuid,
                                done: 0,
                                due: 0,
                                overdue: 0,
                                planed: 0
                            }
                        }
                        const statsName = this.statusNameByValue(row.status)
                        list[uuid][statsName]++
                        this.numStatus[statsName]++
                    })
                    this.data = Object.values(list)
                })
                .finally(() => this.setLoaded());
        }
    },
    async mounted() {
        await this.getData()
    }
}
</script>

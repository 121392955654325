import { render, staticRenderFns } from "./AsaSpinner.vue?vue&type=template&id=5ca70a3b&scoped=true&"
import script from "./AsaSpinner.vue?vue&type=script&lang=js&"
export * from "./AsaSpinner.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ca70a3b",
  null
  
)

export default component.exports
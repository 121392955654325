export default class KeyValueFormatter {
    constructor(settings)
    {
        this.map = settings[0];
        this.attributes = settings[1];
    }

    format(value) {

        return this.map[value];
    }
}

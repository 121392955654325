const STATUS_DONE = 0;
const STATUS_DUE = 1;
const STATUS_OVERDUE = 2;
const STATUS_SUPERVISOR_NOTICED = 4;
const STATUS_PLANED = 3;

export default {
    computed: {
        instructionStatus () {
            return {
                done: STATUS_DONE,
                due: STATUS_DUE,
                overdue: STATUS_OVERDUE,
                planed: STATUS_PLANED,
                supervisor_noticed: STATUS_SUPERVISOR_NOTICED
            }
        }
    },
    methods: {
        statusValueByName(name) {
            if (typeof this.instructionStatus[name] === 'undefined') {
                throw new Error(`Status ${name} does not exist.`);
            }
            return this.instructionStatus[name]
        },
        statusNameByValue(value) {
            return Object.keys(this.instructionStatus).find(key => this.instructionStatus[key] === value)
        }
    }
}
